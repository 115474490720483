import { useContext, useRef } from 'react'
import { useHistory, useLocation } from 'react-router'

import { Button, TabNavigation } from '@sergeimeza/uikit-react'
import { NavigationItem } from '@sergeimeza/uikit-react/lib/Elements/Navigation/NavigationItem'

import { TochigiContext } from '../../providers/TochigiProvider'

import BGImageSmall from '../../assets/images/bg-sp.jpg'
import BGImage from '../../assets/images/bg.jpg'
import GoToTop from '../../assets/images/link-top.png'
import Logo from '../../assets/images/logo.png'

export const TochigiLayout: React.FC<any> = ({ children }) => {
  const layoutRef = useRef<any>(null)
  const tochigiCtx = useContext(TochigiContext)
  const location = useLocation()
  const history = useHistory()

  const RightSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-7 w-7 text-white absolute right-2"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
        clipRule="evenodd"
      />
    </svg>
  )

  async function signOut() {
    await tochigiCtx.signOut()
    window.location.replace('/')
  }

  function otherMenu() {
    history.push('/settings')
  }

  let tabs: NavigationItem[] = [
    {
      name: '体力結果を入力',
      href: '/fitness?step=info',
      current: (() => {
        return location.pathname.includes('/fitness')
      })(),
    },
    {
      name: '体力結果をみる',
      href: '/results',
      current: (() => {
        return location.pathname.includes('/results')
      })(),
    },
  ]

  let menu = (
    <>
      <nav className="nav-global">
        <a href="https://www.tochigi-rising-kids.jp/news/" className="link-news"><img src="https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/text-news.webp" alt="お知らせ・新着情報"/></a>
        <a href="https://www.tochigi-rising-kids.jp/tairyoku/" className="link-tairyoku"><img src="https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/text-tairyoku.webp" alt="栃木県児童生徒の体力ゾーン"/></a>
        <a href="https://www.tochigi-rising-kids.jp/shindan/" className="link-shindan"><img src="https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/text-shindan.webp" alt="体力診断ゾーン"/></a>
        <a href="https://www.tochigi-rising-kids.jp/movies/" className="link-movies"><img src="https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/text-movies.webp" alt="体力アップ動画ゾーン"/></a>
        <a href="https://www.tochigi-rising-kids.jp/exercise/" className="link-exercise"><img src="https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/text-exercise.webp" alt="運動遊びゾーン"/></a>
        <a href="https://www.tochigi-rising-kids.jp/challenge/" className="link-challenge"><img src="https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/text-challenge.webp" alt="チャレンジランキングゾーン"/></a>
        <a href="https://www.tochigi-rising-kids.jp/genkikids/" className="link-genkikids"><img src="https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/text-genkikids.webp" alt="とちぎ元気キッズゾーン"/></a>
        <a href="https://www.tochigi-rising-kids.jp/link/" className="link-link"><img src="https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/text-link.webp" alt="その他・関連リンク等"/></a>
        <a href="https://www.tochigi-rising-kids.jp/about/" className="link-about"><img src="https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/home/link-about.png" alt="とちぎっ子雷ジングひろばとは"/></a>
      </nav>
    </>
  )

  return (
    <div>
      <img
        className="hidden lg:block object-cover h-full w-full mx-auto absolute z-0"
        src={BGImage}
        alt=""
      />
      <img
        className="block lg:hidden object-cover h-full w-full mx-auto absolute z-0"
        src={BGImageSmall}
        alt=""
      />
      <div className="h-screen relative overflow-y-scroll">
        <div ref={layoutRef}></div>
        <div className="flex flex-col justify-between min-h-screen w-full container mx-auto relative z-10 py-10 px-16">
          <div>
            <div className="flex flex-col items-center space-y-5 sm:space-y-10">
              <div className="logo-drop-shadow">
                <a href="https://www.tochigi-rising-kids.jp" target="_self">
                  <img
                    className="w-72 sm:w-96 mx-auto z-10"
                    src={Logo}
                    alt=""
                  />
                </a>
              </div>

              <div className="hidden md:block w-full px-5 sm:px-4">{menu}</div>

              <div className="w-full px-2 sm:px-5">
                {tochigiCtx.loginSession && (
                  <div className="w-full max-w-4xl lg:max-w-full mx-auto px-2 sm:px-8 pb-2 sm:pb-6 overflow-x-auto">
                    <div className="flex justify-between py-2">
                      <div>
                        <TabNavigation
                          tabs={tabs}
                          type={TabNavigation.type.pills}
                          tabItemClassNames="bg-primary-100 text-gray-700 border border-primary-600"
                          tabItemSelectedClassNames="text-white"
                          onTabSelected={(tab) => {
                            tab.href && window.location.replace(tab.href)
                          }}
                        />
                      </div>
                      <div className="flex space-x-4">
                        <Button
                          className="border border-primary-600"
                          onClick={otherMenu}
                          buttonType={Button.buttonType.secondary}
                        >
                          <div className="font-bold">
                            <ruby>
                              設定<rt>せってい</rt>
                            </ruby>
                          </div>
                        </Button>
                        <Button
                          className="inline-flex items-center font-semibold border border-primary-600"
                          onClick={signOut}
                          buttonType={Button.buttonType.secondary}
                        >
                          <div>サインアウト</div>
                        </Button>
                      </div>
                    </div>
                  </div>
                )}

                <div className="w-full relative bg-white-transparent rounded-lg max-w-4xl lg:max-w-full mx-auto px-2 sm:px-8 pt-2 pb-36 sm:pt-12 sm:pb-40 shadow-lg border border-blue-100 overflow-x-auto">
                  {children}
                </div>
              </div>
            </div>

            <div className="block md:hidden w-full px-5 sm:px-4 mt-10">
              {menu}
            </div>

            <div className="z-10 flex justify-center sm:justify-end mr-0 sm:mr-10 mt-10">
              <button
                type="button"
                onClick={() => {
                  layoutRef.current.scrollIntoView()
                  window.scrollTo(0, 0)
                }}
              >
                <img className="w-24 sm:w-36" src={GoToTop} alt="" />
              </button>
            </div>
          </div>
          <div className="mt-10">
            <div className="flex flex-col items-center mx-auto">
              <div className="flex flex-col md:flex-row  md:space-x-10 space-y-4 md:space-y-0">
                <a
                  className="bg-green-500 hover:bg-white rounded-full w-60 text-center tracking-widest text-white text-base py-1 hover:text-green-500"
                  href="https://www.tochigi-rising-kids.jp/contact/"
                >
                  お問い合わせ
                </a>
                <a
                  className="bg-green-500 hover:bg-white rounded-full w-60 text-center tracking-widest text-white text-base py-1 hover:text-green-500"
                  href="https://www.tochigi-rising-kids.jp/#"
                >
                  ご利用について
                </a>
                <a
                  className="bg-green-500 hover:bg-white rounded-full w-60 text-center tracking-widest text-white text-base py-1 hover:text-green-500"
                  href="https://www.tochigi-rising-kids.jp/privacy/"
                >
                  個人情報の取り扱いについて
                </a>
              </div>
            </div>
            <div className="mt-4 text-center mb-8 mx-auto">
              <span className="text-sm text-white tracking-widest">
                ©栃木県教育委員会事務局スポーツ振興課
              </span>
            </div>

            <div className="text-center text-xs text-yellow-600">
              ユーザーID: {tochigiCtx.userCredential?.uid}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
